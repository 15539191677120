@media print {
  @page {
    size: A4 portrait;
  }

  body * {
    overflow: visible !important;

    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }

  html,
  body,
  #root,
  .app {
    height: auto !important;
    position: static !important;
  }

  #action-buttons-container {
    display: none !important;
  }

  #viewer {
    display: none !important;
  }

  #thumbnail {
    display: block !important;
  }

  #scroll-container {
  }

  #viewer-container {
    width: 100%;
    height: 300px !important;
  }

  #viewer-buttons-container {
    display: none !important;
  }

  #ar-modal {
    display: none !important;
  }

  #info-container {
    width: 100%;
  }

  #qr-code-container {
    width: 100%;
    padding-bottom: 0 !important;
  }
}
