@font-face {
    font-family: 'DIN Pro Cond';
    src: url('DINPro-CondensedBlack.eot');
    src: local('DIN Pro Condensed Black'), local('DINPro-CondensedBlack'),
        url('DINPro-CondensedBlack.eot?#iefix') format('embedded-opentype'),
        url('DINPro-CondensedBlack.woff2') format('woff2'),
        url('DINPro-CondensedBlack.woff') format('woff'),
        url('DINPro-CondensedBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Light.eot');
    src: local('DIN Pro Light'), local('DINPro-Light'),
        url('DINPro-Light.eot?#iefix') format('embedded-opentype'),
        url('DINPro-Light.woff2') format('woff2'),
        url('DINPro-Light.woff') format('woff'),
        url('DINPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro.eot');
    src: local('DIN Pro'), local('DINPro'),
        url('DINPro.eot?#iefix') format('embedded-opentype'),
        url('DINPro.woff2') format('woff2'),
        url('DINPro.woff') format('woff'),
        url('DINPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-BoldItalic.eot');
    src: local('DIN Pro Bold Italic'), local('DINPro-BoldItalic'),
        url('DINPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('DINPro-BoldItalic.woff2') format('woff2'),
        url('DINPro-BoldItalic.woff') format('woff'),
        url('DINPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Bold.eot');
    src: local('DIN Pro Bold'), local('DINPro-Bold'),
        url('DINPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('DINPro-Bold.woff2') format('woff2'),
        url('DINPro-Bold.woff') format('woff'),
        url('DINPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('DINPro-CondensedBoldItalic.eot');
    src: local('DIN Pro Condensed Bold Italic'), local('DINPro-CondensedBoldItalic'),
        url('DINPro-CondensedBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('DINPro-CondensedBoldItalic.woff2') format('woff2'),
        url('DINPro-CondensedBoldItalic.woff') format('woff'),
        url('DINPro-CondensedBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-MediumItalic.eot');
    src: local('DIN Pro Medium Italic'), local('DINPro-MediumItalic'),
        url('DINPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('DINPro-MediumItalic.woff2') format('woff2'),
        url('DINPro-MediumItalic.woff') format('woff'),
        url('DINPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Black.eot');
    src: local('DIN Pro Black'), local('DINPro-Black'),
        url('DINPro-Black.eot?#iefix') format('embedded-opentype'),
        url('DINPro-Black.woff2') format('woff2'),
        url('DINPro-Black.woff') format('woff'),
        url('DINPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('DINPro-CondensedMedium.eot');
    src: local('DIN Pro Condensed Medium'), local('DINPro-CondensedMedium'),
        url('DINPro-CondensedMedium.eot?#iefix') format('embedded-opentype'),
        url('DINPro-CondensedMedium.woff2') format('woff2'),
        url('DINPro-CondensedMedium.woff') format('woff'),
        url('DINPro-CondensedMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('DINPro-CondensedLight.eot');
    src: local('DIN Pro Condensed Light'), local('DINPro-CondensedLight'),
        url('DINPro-CondensedLight.eot?#iefix') format('embedded-opentype'),
        url('DINPro-CondensedLight.woff2') format('woff2'),
        url('DINPro-CondensedLight.woff') format('woff'),
        url('DINPro-CondensedLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Medium.eot');
    src: local('DIN Pro Medium'), local('DINPro-Medium'),
        url('DINPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('DINPro-Medium.woff2') format('woff2'),
        url('DINPro-Medium.woff') format('woff'),
        url('DINPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Italic.eot');
    src: local('DIN Pro Italic'), local('DINPro-Italic'),
        url('DINPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('DINPro-Italic.woff2') format('woff2'),
        url('DINPro-Italic.woff') format('woff'),
        url('DINPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('DINPro-CondensedLightItalic.eot');
    src: local('DIN Pro Condensed Light Italic'), local('DINPro-CondensedLightItalic'),
        url('DINPro-CondensedLightItalic.eot?#iefix') format('embedded-opentype'),
        url('DINPro-CondensedLightItalic.woff2') format('woff2'),
        url('DINPro-CondensedLightItalic.woff') format('woff'),
        url('DINPro-CondensedLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('DINPro-CondensedMediumItalic.eot');
    src: local('DIN Pro Condensed Medium Italic'), local('DINPro-CondensedMediumItalic'),
        url('DINPro-CondensedMediumItalic.eot?#iefix') format('embedded-opentype'),
        url('DINPro-CondensedMediumItalic.woff2') format('woff2'),
        url('DINPro-CondensedMediumItalic.woff') format('woff'),
        url('DINPro-CondensedMediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('DINPro-CondensedBlackItalic.eot');
    src: local('DIN Pro Condensed Black Italic'), local('DINPro-CondensedBlackItalic'),
        url('DINPro-CondensedBlackItalic.eot?#iefix') format('embedded-opentype'),
        url('DINPro-CondensedBlackItalic.woff2') format('woff2'),
        url('DINPro-CondensedBlackItalic.woff') format('woff'),
        url('DINPro-CondensedBlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('DINPro-CondensedBold.eot');
    src: local('DIN Pro Condensed Bold'), local('DINPro-CondensedBold'),
        url('DINPro-CondensedBold.eot?#iefix') format('embedded-opentype'),
        url('DINPro-CondensedBold.woff2') format('woff2'),
        url('DINPro-CondensedBold.woff') format('woff'),
        url('DINPro-CondensedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-BlackItalic.eot');
    src: local('DIN Pro Black Italic'), local('DINPro-BlackItalic'),
        url('DINPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('DINPro-BlackItalic.woff2') format('woff2'),
        url('DINPro-BlackItalic.woff') format('woff'),
        url('DINPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-LightItalic.eot');
    src: local('DIN Pro Light Italic'), local('DINPro-LightItalic'),
        url('DINPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('DINPro-LightItalic.woff2') format('woff2'),
        url('DINPro-LightItalic.woff') format('woff'),
        url('DINPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('DINPro-CondensedItalic.eot');
    src: local('DIN Pro Condensed Italic'), local('DINPro-CondensedItalic'),
        url('DINPro-CondensedItalic.eot?#iefix') format('embedded-opentype'),
        url('DINPro-CondensedItalic.woff2') format('woff2'),
        url('DINPro-CondensedItalic.woff') format('woff'),
        url('DINPro-CondensedItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('DINPro-CondensedRegular.eot');
    src: local('DIN Pro Condensed Regular'), local('DINPro-CondensedRegular'),
        url('DINPro-CondensedRegular.eot?#iefix') format('embedded-opentype'),
        url('DINPro-CondensedRegular.woff2') format('woff2'),
        url('DINPro-CondensedRegular.woff') format('woff'),
        url('DINPro-CondensedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

